import * as React from "react";
import { Link } from "gatsby";
import {useEffect, useState} from "react";

const IndexPage = () => {
  const [weather, setWeather] = useState(null);
  const parseWeather = data => {
    setWeather(data);
  };

  useEffect(() => {
    const fullCity = "Kyiv";
    const city = encodeURIComponent(fullCity);
    fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=e3727adf358f8ed1896d0c17d8719c16&units=metric`
    )
        .then(response => response.json())
        .then(data => {
          parseWeather(data);
        })
        .catch(() => {
          console.log("Weather API Error: Something went wrong!");
        });
  }, []);

  return (
    <div>
      <div>
        <Link to="/about" color="secondary">
          about page
        </Link>
        <Link to="/" color="secondary">
          main page
        </Link>
      </div>

      {weather && (
          <React.Fragment>
            <div>
              <p>
                <b>Weather in Kyiv:&nbsp;</b>
                {weather.weather[0].description}&nbsp;
                <b>Temperature:&nbsp;</b>
                {weather.main.temp}C &nbsp;
                <b>Pressure:&nbsp;</b>
                {weather.main.pressure}
              </p>
            </div>
          </React.Fragment>
      )}
    </div>
  )
}

export default IndexPage
